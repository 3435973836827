<template>
  <div class="container" id="androidHelp">
    <p>
      亲爱的用户，您好，欢迎您使用巨立云系统，以下为您介绍系统的相关功能和注意事项，敬请查阅，谢谢！
    </p>
    <p>1.安装程序授权问题</p>
    <p>安装程序时当出现“定位功能授权”等权限提示时，请均选择同意。</p>
    <p>2.重新登录问题</p>
    <p>
      如果出现“请重新登录”提示，一般为登录秘钥过期，此时请点击“我的”，然后点击“退出登录”，再次登录即可
    </p>
    <p>3.打卡功能介绍</p>
    <p>
      登录后进入系统需点击主页面上的打卡功能，切换状态（“在线”：正常上班状态；“待命”：下班但可以接受和执行困人维修；“离线”：彻底下班休息，不再接受任何维修指令
    </p>
    <p style="color: red">
      当用户处于“在线”或“待命”状态，如果需要暂时离开APP，请使用手机的Home键将APP放在后台运行，一定不要
      使用手机的一键清理功能彻底关闭APP；如果用户需要下班并不再接受维修指令，请先点击维保主界面的状态切
      换键选择“离线”然后再关闭APP
    </p>
    <p style="color: red">
      注意：严禁用户处于“在线”或“待命”两种状态下直接通过一键清理功能关闭APP。
    </p>
    <p>4.电梯基础数据问题</p>
    <p>
      当发现所执行的工单中电梯的类型（扶梯、直梯等）不符，请联系服务中心相关人员修改，并暂停此工单的执行
    </p>
    <p>5.底坑操作问题</p>
    <p>
      由于底坑网络信号较差，请在进入底坑之前确保打开APP进入对应工单的执行页面
    </p>
    <p>6.图片提交问题</p>
    <p>
      当提交工单时，如果出现
      “图片提交失败。。。”等提示，请检查手机的网络是否正常（是否可以正常刷网页），
      如不正常，请到网络通畅的地方重新提交。
      如果依旧不可以提交，请重启手机再次提交，并在“我的”模块下“意见反馈”将此现象反馈给我们，谢谢合作！
    </p>
    <p>7.小米手机扫描框黑屏问题</p>
    <p>
      小米手机扫描框黑屏问题说明：打开扫描二维码出现黑屏，原因是禁止了程序拍照的权限（此时可以正常拍照，但不能扫码）
      解决：进入手机的“安全中心”（手机主界面），进入“授权管理”，选择“应用权限管理”，找到此APP，点击进入，此时的
      “相机”功能是 红叉（不可用），点击使其可用。
    </p>
    <p>8.意见反馈</p>
    <p>
      在使用过程中如果遇到其他问题或者意见，请在“意见反馈”中反馈给我们，我们将持续改进，感谢使用！
    </p>
  </div>
</template>

<script>
import { reactive, toRefs } from "vue";
export default {
  name: "AndroidHelp",
  setup(props, { emit }) {
    emit("public_header", false);

    const state = reactive({});

    const init = () => {};

    init();

    return {
      ...toRefs(state),
    };
  },
};
</script>

<style lang="scss"></style>
